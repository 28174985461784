import type { LocaleType, ProjectEnvironmentType } from '~/types/common';

export const DOMAINS = {
  development: {
    en: 'en.domain.localhost:5001',
    ru: 'domain.localhost:5001',
  },
  demonstration: {
    en: 'en.d.sex-play.ru',
    ru: 'd.sex-play.ru',
  },
  production: {
    en: 'en.sex-play.ru',
    ru: 'sex-play.ru',
  },
} satisfies Record<ProjectEnvironmentType, Record<LocaleType, string>>;

export default (
  environment: string,
  type: 'domain' | 'host',
  locale: 'ru' | 'en',
) =>
  `${
    type === 'domain'
      ? ''
      : `http${environment === 'development' ? '' : 's'}://`
  }${DOMAINS[environment as ProjectEnvironmentType][locale]}`;
