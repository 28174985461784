/* eslint-disable @typescript-eslint/no-explicit-any */
export default defineNuxtPlugin((nuxtApp) => {
  const serverSideRequestKey =
    nuxtApp.ssrContext?.runtimeConfig.serverSideRequestKey;
  const initialOptions: StrictFetchOptions = {
    baseURL: `${nuxtApp.ssrContext?.runtimeConfig.apiServerHost || ''}/api/`,
    headers: serverSideRequestKey
      ? { 'X-Server-Side-Request-Key': serverSideRequestKey }
      : {},
    proxyServerCookies: true,
    onRequest: (context) => {
      for (const key in context.options.params) {
        if (
          Array.isArray(context.options.params[key]) &&
          context.options.params[key].length
        ) {
          context.options.params[key] = context.options.params[key].join(',');
        }
      }
    },
  };

  const refreshState = {
    isRequest: false,
    subscribers: [] as {
      resolve: (v: unknown) => void;
      reject: (v: unknown) => void;
    }[],
  };

  StrictFetch.init({
    ...initialOptions,
    catch: (error) => {
      if (error.context?.response?.status === 401) {
        if (!refreshState.isRequest) {
          refreshState.isRequest = true;
          return $fetch('v2/user/refresh', {
            ...initialOptions,
            method: HTTPMethod.post,
          })
            .then(() => {
              refreshState.subscribers.forEach((el) => el.resolve(true));
              refreshState.subscribers = [];
              return $fetch.raw(
                error.context?.request || '',
                error.context?.options as any,
              );
            })
            .catch(() => {
              refreshState.subscribers.forEach((el) => el.reject(error));
              refreshState.subscribers = [];
              return Promise.reject(error);
            })
            .finally(() => {
              refreshState.isRequest = false;
            });
        } else {
          return new Promise((resolve, reject) => {
            refreshState.subscribers.push({
              resolve,
              reject,
            });
          }).then(
            () =>
              $fetch.raw(
                error.context?.request || '',
                error.context?.options as any,
              ) as any,
          );
        }
      }
      return Promise.reject(error);
    },
  });

  return { provide: { requestRefreshState: refreshState } };
});
