import revive_payload_client_ABcSOUtAfq from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qe2efixvhbmh2adttcszmap3na/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_AmR85lB8lL from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qe2efixvhbmh2adttcszmap3na/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_i3NPrphWsa from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qe2efixvhbmh2adttcszmap3na/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_Mau8nEBYZN from "/app/node_modules/.pnpm/nuxt-site-config@2.2.16_magicast@0.3.5_rollup@4.21.1_vite@5.4.4_@types+node@22.5.5_sass@1.78._2xovk2bdph4etiqijor4ivzzjq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_7yjp31AwUn from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qe2efixvhbmh2adttcszmap3na/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_QJnq8r7tnz from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qe2efixvhbmh2adttcszmap3na/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_IEsHYUz0Oy from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qe2efixvhbmh2adttcszmap3na/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_AoVv2x8P7J from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qe2efixvhbmh2adttcszmap3na/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_cpMoivPs99 from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.1_typescript@5.6.2_vue@3.5.5_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_fg2WJ38muo from "/app/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_qe2efixvhbmh2adttcszmap3na/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_KYTQsruTKZ from "/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_magicast@0.3.5_rollup@4.21.1_vite@5.4.4_@types+node@22.5.5_sass@1.78.0__tu2jpx2wlvz7okesi6ltatt2ry/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import primevue_plugin_egKpok8Auk from "/app/.nuxt/primevue-plugin.mjs";
import plugin_client_rvMC95UScf from "/app/node_modules/.pnpm/@primevue+nuxt-module@4.0.7_@babel+parser@7.25.6_magicast@0.3.5_rollup@4.21.1_vue@3.5.5_typescript@5.6.2_/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_HaxY4p4UJ9 from "/app/node_modules/.pnpm/nuxt-strict-fetch@0.7.2_magicast@0.3.5_rollup@4.21.1/node_modules/nuxt-strict-fetch/dist/runtime/plugin.js";
import switch_locale_path_ssr_7soB8sVd62 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.1_vue@3.5.5_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_GAp1yyVQz8 from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.5.3_magicast@0.3.5_rollup@4.21.1_vue@3.5.5_typescript@5.6.2_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import autoAnimate_4HQGapz9xs from "/app/plugins/autoAnimate.ts";
import request_8cwBTcUfTa from "/app/plugins/request.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import showOnVisible_MqBxBtg92A from "/app/plugins/showOnVisible.ts";
export default [
  revive_payload_client_ABcSOUtAfq,
  unhead_AmR85lB8lL,
  router_i3NPrphWsa,
  _0_siteConfig_Mau8nEBYZN,
  payload_client_7yjp31AwUn,
  navigation_repaint_client_QJnq8r7tnz,
  check_outdated_build_client_IEsHYUz0Oy,
  chunk_reload_client_AoVv2x8P7J,
  plugin_vue3_cpMoivPs99,
  components_plugin_KR1HBZs4kY,
  prefetch_client_fg2WJ38muo,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_KYTQsruTKZ,
  primevue_plugin_egKpok8Auk,
  plugin_client_rvMC95UScf,
  plugin_HaxY4p4UJ9,
  switch_locale_path_ssr_7soB8sVd62,
  i18n_GAp1yyVQz8,
  autoAnimate_4HQGapz9xs,
  request_8cwBTcUfTa,
  sentry_client_shVUlIjFLk,
  showOnVisible_MqBxBtg92A
]